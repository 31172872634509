import layoutApp from '@/components/Layout/App.vue'
import layoutMain from '@/components/Layout/layoutMain.vue'

export default [
  {
    path: '/',
    component: layoutApp,
    redirect: { name: 'home' },
    children: [
      {
        path: '/',
        name: 'home',
        meta: { title: '首页', icon: 'el-icon-s-home', requireAuth: true },
        component: () => import('@/views/home')
      },
      {
        path: '/product',
        meta: { title: '产品与项目', icon: 'el-icon-data-analysis' },
        redirect: { name: 'product' },
        component: layoutMain,
        children: [
          {
            path: '/product',
            name: 'product',
            meta: {
              title: '产品列表',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () => import('@/views/product/index.vue')
          },
          {
            path: '/project/:id?',
            name: 'project',
            meta: {
              title: '项目列表',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () => import('@/views/project/index.vue')
          },
          {
            path: '/project/detail/:id',
            name: 'project-detail',
            show: true,
            redirect: { name: 'base-info' },
            meta: {
              title: '项目详情页',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () => import('@/views/project_detail/index.vue'),
            children: [
              {
                path: '/project/detail/base-info/:id',
                name: 'base-info',
                show: true,
                meta: {
                  title: '项目基础信息',
                  requireAuth: true
                },
                component: () => import('@/views/project_detail/base-info/index.vue')
              },
              {
                path: '/project/detail/material-info/:id',
                name: 'material-info',
                show: true,
                // redirect: { name: 'current-material' },
                meta: {
                  title: '项目开发产物',
                  requireAuth: true
                },
                component: () => import('@/views/project_detail/material-info/index.vue'),
                children: [
                  {
                    path: '/project/detail/material-info/current/:id',
                    name: 'current-material',
                    show: true,
                    meta: {
                      title: '本地产物',
                      requireAuth: true
                    },
                    component: () => import('@/views/project_detail/current-material/index.vue')
                  },
                  {
                    path: '/project/detail/material-info/quote/:id',
                    name: 'quote-material',
                    show: true,
                    meta: {
                      title: '引用产物',
                      requireAuth: true
                    },
                    component: () => import('@/views/project_detail/quote-material/index.vue')
                  },
                ]
              },
              {
                path: '/project/detail/email-record/:id',
                name: 'email-record',
                show: true,
                meta: {
                  title: '项目邮件信息',
                  requireAuth: true
                },
                component: () => import('@/views/project_detail/email-record/index.vue')
              },
              {
                path: '/project/detail/evaluate/:id',
                name: 'email-record',
                show: true,
                meta: {
                  title: '报价',
                  requireAuth: true
                },
                component: () => import('@/views/project_detail/evaluate/index.vue')
              },
              {
                path: '/project/detail/order-info/:id',
                name: 'order-info',
                show: true,
                meta: {
                  title: '项目订单信息',
                  requireAuth: true
                },
                component: () => import('@/views/project_detail/order-info/index.vue')
              },
            ]
          },
          {
            path: '/project/material/detail/:code',
            name: 'materialDetail',
            show: true,
            meta: {
              title: '产物详情页',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () => import('@/views/project/material/index.vue')
          }
        ]
      },
      {
        path: '/order',
        name: 'order',
        meta: {
          title: '订单',
          icon: 'el-icon-data-analysis',
          requireAuth: true
        },
        component: layoutMain,
        children: [
          {
            path: '/order',
            name: 'order',
            meta: {
              title: '订单列表',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () => import('@/views/order/index.vue')
          }
        ]
      },
      {
        path: '/manage',
        name: 'manage',
        meta: {
          title: '管理',
          icon: 'el-icon-data-analysis',
          requireAuth: true
        },
        component: layoutMain,
        children: [
          {
            path: '/customer',
            name: 'customer',
            meta: {
              title: '客户',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () => import('@/views/customer/index.vue')
          },
          {
            path: '/manage/platform',
            name: 'platform',
            meta: {
              title: '平台',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () => import('@/views/platform/index.vue')
          },
          {
            path: '/manage/identification',
            name: 'manage_identification',
            meta: {
              title: '产品类别标识',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () =>
              import('@/views/product_category_identification/index.vue')
          },
          {
            path: '/manage/chip',
            name: 'chip',
            meta: {
              title: '芯片',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () => import('@/views/chip/index.vue')
          },
          {
            path: '/manage/chip/type',
            name: 'chipType',
            meta: {
              title: '芯片类型',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () => import('@/views/chip_type/index.vue')
          },
          {
            path: '/manage/module',
            name: 'module',
            meta: {
              title: '模块',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () => import('@/views/module/index.vue'),
          },
          {
            path: '/module/type',
            name: 'module_type',
            meta: {
              title: '模块类型',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () =>
              import('@/views/module_type/index.vue')
          },
          {
            path: '/supplier',
            name: 'supplier',
            meta: {
              title: '供应商',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () =>
              import('@/views/supplier/index.vue')
          },
          {
            path: '/email',
            name: 'email',
            meta: {
              title: '邮件',
              icon: 'el-icon-data-analysis',
              requireAuth: true
            },
            component: () =>
              import('@/views/email/index.vue')
          },
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/preview',
    name: 'preview',
    component: () => import('@/views/preview')
  }
]
