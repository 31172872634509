import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')
  if (to.name !== 'login' && to.meta.requireAuth && !token) {
    console.log("form", from)
    console.log("to", to)
    next({ name: 'login', query: {backUrl: to.fullPath} })
  } else {
    // 目标路由是登录页-自然不需要token验证
    // 或目标路由不需要身份验证
    // 又或目标路由非登录页，需要token验证，但是有token
    // next放行
    document.title = "小匠研产销"
    next()
  }
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}
export default router
